import { defineStore } from 'pinia'

import actions from './actions'
import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'

export const useEventsStore = defineStore('eventsStore', {
  state: () => ({
    events: [],
    selectedEventId: null,
  }),
  actions,
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    latestEvent(state) {
      return state.events[0] ?? null
    },

    /**
     * @param state
     * @returns {string|undefined}
     */
    currentSelectedEventId(state) {
      return state.selectedEventId ?? state.events[0]?.id
    },

    /**
     * @param state
     * @returns {function(string): momentshare.models.event.Event|undefined}
     */
    getEventById: (state) => (id) => {
      return state.events.find((event) => event.id === id)
    },

    /**
     * @param state
     * @returns {boolean}
     */
    hasOnlyMomentshareDriveEvents: (state) => {
      return (
        state.events.length &&
        state.events.every(
          (event) => event.properties.find((property) => property.key === EVENT_PROPERTY_KEY.HOSTING_ENABLED)?.value,
        )
      )
    },
  },
})
