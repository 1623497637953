export class WakeLockService {
  constructor() {
    this.wakeLock = null
  }

  async preventScreenSleep() {
    if ('wakeLock' in navigator) {
      try {
        this.wakeLock = await navigator.wakeLock.request('screen')
      } catch (err) {
        console.error(`Failed to request screen wake lock: ${err}`)
        this.wakeLock = null
      }
    } else {
      console.warn('navigator.wakeLock is not available')
    }
  }

  async enableScreenSleep() {
    if (this.wakeLock) {
      try {
        this.wakeLock.release()
      } catch (err) {
        console.error(`Failed to release screen wake lock: ${err}`)
      } finally {
        this.wakeLock = null
      }
    }
  }
}
