/**
 * @typedef {StorageService}
 * @alias this.$storageService
 */
export class StorageService {
  readonly keyPrefix = 'ms-storage-'
  readonly local: BrowserStorage
  readonly session: BrowserStorage

  constructor() {
    this.local = new BrowserStorage(this, 'localStorage')
    this.session = new BrowserStorage(this, 'sessionStorage')
  }

  isStorageTypeSupported(storage: 'localStorage' | 'sessionStorage') {
    if (!window) {
      return false
    }

    return !(typeof window[storage] !== 'object' || !window[storage]['setItem'] || !window[storage]['removeItem'])
  }
}

class BrowserStorage {
  readonly supported: boolean
  readonly storage: Storage | Record<string, never>
  readonly keyPrefix: string

  constructor(
    public StorageService: StorageService,

    mod: 'localStorage' | 'sessionStorage',
  ) {
    this.supported = StorageService.isStorageTypeSupported(mod)
    this.storage = this.supported ? window[mod] : {}
    this.keyPrefix = StorageService.keyPrefix

    this.purgeExpired()
  }

  getStorageKey(key: string): string {
    return this.keyPrefix + key
  }

  has(key: string): boolean {
    return this.storage[this.getStorageKey(key)] !== undefined
  }

  /**
   * Get value by key
   */
  get(key: string, defaultValue: any = false) {
    const has = this.has(key)

    if (!has) {
      return defaultValue
    }

    return JSON.parse(this.getDefinition(key)['v'])
  }

  /**
   * Get definition object with properties: c (created), t (ttl), v (value)
   */
  getDefinition(key: string): any {
    return this.has(key) ? JSON.parse(this.storage[this.getStorageKey(key)]) : false
  }

  /**
   * Set a key/value in storage. ttl is optional
   */
  set(key: string, value: any, ttl?: number): boolean {
    if (!this.supported) {
      return false
    }

    this.storage[this.getStorageKey(key)] = JSON.stringify({
      c: new Date().getTime(),
      t: typeof ttl === 'number' ? ttl : null,
      v: JSON.stringify(value),
    })

    return true
  }

  remove(key: string): boolean {
    delete this.storage[this.getStorageKey(key)]

    return true
  }

  /**
   * Get all keys owned by etStorageService from storage (checks for prefix)
   */
  all(): string[] {
    const owned: string[] = []

    Object.keys(this.storage).forEach((key) => {
      if (key.indexOf(this.keyPrefix) !== 0) {
        return
      }

      owned.push(key.substring(this.keyPrefix.length))
    })

    return owned
  }

  /**
   * Check every key in storage for TTL and remove when expired
   */
  purgeExpired(): void {
    const now = new Date().getTime()

    this.all().forEach((key) => {
      const def = this.getDefinition(key)

      if (def.t === null) {
        return
      }

      if ((def.c + def.t) * 1000 < now) {
        this.remove(key)
      }
    })
  }

  /**
   * Remove all storage keys starting with given prefix
   */
  purgeByPrefix(prefix: string): void {
    this.all().forEach((key) => {
      if (key.indexOf(prefix) === 0) {
        this.remove(key)
      }
    })
  }
}
