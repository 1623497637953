import axios from 'axios'
import { UserAddress } from '~/lib/models/user/user-address'
import { mapVatNumberResult } from '~/lib/mappers/vat-number-result-mapper'

export class VatService {
  constructor() {
    this.mappedResponses = {}
  }

  /**
   * @param {string} vatNumber
   * @return {Object} vatNumberResult
   */
  async getVatNumberDetails(vatNumber) {
    if (!vatNumber) {
      return false
    }

    if (this.mappedResponses[vatNumber]) {
      return this.mappedResponses[vatNumber]
    }

    return axios.get(`https://controleerbtwnummer.eu/api/validate/${vatNumber}.json`).then((response) => {
      const mappedResponse = mapVatNumberResult(response.data)

      this.mappedResponses[vatNumber] = mappedResponse

      return mappedResponse
    })
  }

  /**
   * @param {momentshare.models.vat.VatNumberResult} vatNumberResult
   * @param {Object} inputAddress
   * @return {boolean}
   */
  validateVatNumberWithPostalCodeAndCountry(vatNumberResult, inputAddress) {
    const transformedAddress = this.transformToAddress(vatNumberResult)
    const isPostalCodeValid =
      transformedAddress.postalCode &&
      inputAddress.postalCode &&
      transformedAddress.postalCode?.toLowerCase().replace(/ /g, '') ===
        inputAddress.postalCode?.toLowerCase().replace(/ /g, '')
    const isCountryValid = transformedAddress.country === inputAddress.country

    return vatNumberResult.valid && isPostalCodeValid && isCountryValid
  }

  /**
   * @param {momentshare.models.vat.VatNumberResult} vatNumberResult
   * @return {momentshare.models.user.UserAddress|undefined}
   */
  transformToAddress(vatNumberResult) {
    if (!vatNumberResult.valid) {
      return
    }

    return new UserAddress({
      name: vatNumberResult.name,
      address: `${vatNumberResult.address?.street} ${vatNumberResult.address?.number.replace(/^0+/, '')}`,
      postalCode: vatNumberResult.address?.zip_code,
      city: vatNumberResult.address?.city,
      country: vatNumberResult.countryCode,
      vatNumber: vatNumberResult.vatNumber,
    })
  }
}
